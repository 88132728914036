<template>
  <div style="height: inherit">
    <div class="email-app-list position-relative space-bottom">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-embed
                id="container"
                type="iframe"
                :src="urlMarket"
                allowfullscreen
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>

</template>

<script>
import store from '@/store'
import {
  VBTooltip, BCard, BCardBody, BRow, BCol, BEmbed,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import marketModule from '@store-modules/listings/marketStudy/index'
import { mapGetters } from 'vuex'
import { useRouter } from '@core/utils/utils'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BEmbed,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      prevRoute: null,
      titleReport: '',
    }
  },
  computed: {
    ...mapGetters('market', ['paramsUrl', 'urlMarket']),
    ...mapGetters('auth', ['currentUser']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  watch: {
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm
      self.prevRoute = from
    })
  },
  mounted() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.add('capacitor')
    }
  },
  destroyed() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.remove('capacitor')
    }
  },
  async created() {
    try {
      this.$root.$emit('app::loading', true)

      const { idImovel } = this.paramsUrl

      await store.dispatch('market/getMarketStudy', { id: idImovel }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$root.$emit('app::loading', false)
    } catch (err) {
      // err
    }
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async redirectPageBack() {
      let urlPrevRoute = this.prevRoute.path
      if (urlPrevRoute === '/') {
        urlPrevRoute = '/dashboard'
      }

      this.$router.push({ path: urlPrevRoute }).then(() => {
        this.$root.$emit('app::loading', false)
      })
    },
  },
  setup() {
    const MARKET_MODULE_NAME = 'market'

    if (!store.hasModule(MARKET_MODULE_NAME)) {
      store.registerModule(MARKET_MODULE_NAME, marketModule)

      onUnmounted(() => {
        if (store.hasModule(MARKET_MODULE_NAME)) store.unregisterModule(MARKET_MODULE_NAME)
      })
    }

    const { route } = useRouter()
    const paramsUrl = route.value.params
    store.dispatch('market/setParamsUrl', paramsUrl)

    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.email-app-list #container,
.email-app-list .embed-responsive {
  height: calc( 100vh - calc(8.4rem) )!important;
}

</style>
